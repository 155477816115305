/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Button } from "reactstrap";
import { routes } from '../../config';
import "./index.scss";

import { InputField, Loader, SelectField } from '../UIComponents';
import MultiSelectField from "../../containers/UIComponents/MultiSelectField";
import { checkProvidedOptions } from "../../utils/function-utils";
import { extractCountryCodeAndName } from "../../helpers/function";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastrService } from '../../services';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

window.recaptchaOptions = {
  lang: i18n.language,
  useRecaptchaNet: true,
  removeOnUnmount: true,
};

class RegisterComponent extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    phoneNumberCountryCode: '(+1) - United States',
    errors: {},
    facility: '',
    department: '',
    profession: '',
    specialty: '',
    suffix: '',
    isFacilitiesListOpen: false,
    isDepartmentsOpen: false,
    isProfessionOpen: false,
    isSpecialtyOpen: false,
    isSuffixOpen: false,
    eula: false
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  componentDidMount() {
    const { token, isSelfRegistrationEnabled } = this.props;
    if (isSelfRegistrationEnabled === null) {
      this.props.getSelfRegistrationStatus();
    }
    const params = { pageSize: 10000 }
    this.props.getFacilitiesList(params)
    this.props.getDepartmentsList(params)
    this.props.getProfessions(params)
    this.props.getSpecialties(params)
    this.props.getSuffixesList(params)
    if (!!token) {
      this.props.history.push(routes.recent.path)
    } else if (isSelfRegistrationEnabled === false) {
      this.props.history.push(routes.login.path)
    }
    this.props.getCallingCodesList();
  }

  componentDidUpdate(prevProps) {
    const { token, history, loading, error, isSelfRegistrationEnabled, tempToken } = this.props, { loading: prevLoading } = prevProps;
    if (!!token) {
      history.push(routes.recent.path);
      return
    }
    if (isSelfRegistrationEnabled === false) {
      this.props.history.push(routes.login.path)
      return;
    }
    if (!loading && prevLoading && !error && tempToken) {
      history.push(routes.emailConfirm.path);
    }
  }

  acceptEULA = () => {
    this.setState({ eula: !this.state.eula})
  }

  handleRegisterClick = () => {
    const captchaResponse = this.recaptcha.getValue();
    const { t } = this.props;
    // if (!captchaResponse) return ToastrService.error(t('captcha'))
    const { firstName, lastName, phone, email, facility, department, profession, suffix, specialty, password, confirmPassword, phoneNumberCountryCode } = this.state, errors = {};
    let isValid = true;
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const phoneRe = /^\d+$/;

    if (!firstName) {
      errors.firstName = t('Required');
      isValid = false;
    }

    if (!lastName) {
      errors.lastName = t('Required');
      isValid = false;
    }

    if (!email) {
      errors.email = t('Required');
      isValid = false;
    } else if (!re.test(email)) {
      errors.email = t('invalid_email');
      isValid = false;
    }

    if (!facility || (facility && !facility.length)) {
      errors.facility = t('Required');
      isValid = false;
    }

    if (!department || (department && !department.length)) {
      errors.department = t('Required');
      isValid = false;
    }

    if (!profession || (profession && !profession.length)) {
      errors.profession = t('Required');
      isValid = false;
    }

    if (!specialty || (specialty && !specialty.length)) {
      errors.specialty = t('Required');
      isValid = false;
    }

    if (!suffix || (suffix && !suffix.length)) {
      errors.suffix = t('Required');
      isValid = false;
    }

    if (!password) {
      errors.password = t('Required');
      isValid = false;
    } else if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
      isValid = false;
    } else if (!(/.*[A-Z].*/).test(password)) {
      errors.password = 'Password should contain at least one uppercase character';
      isValid = false;
    } else if (!(/.*[a-z].*/).test(password)) {
      errors.password = 'Password should contain at least one lowercase character';
      isValid = false;
    } else if (!(/.*\d.*/).test(password)) {
      errors.password = 'Password should contain at least one number';
      isValid = false;
    }

    if (!confirmPassword) {
      errors.confirmPassword = t('Required');
      isValid = false;
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Doesn't match with password";
      isValid = false;
    }

    if (!phone) {
      errors.phone = t('Required');
      isValid = false;
    } else if (!phoneRe.test(phone)) {
      errors.phone = 'Invalid phone format';
      isValid = false;
    }
    this.setState({ errors });
    if(isValid && !this.state.eula) {
      ToastrService.info('You must accept our EULA!');
      return;
    } 
    if (isValid) {
      const { phoneCountryCode, phoneCountryName } = extractCountryCodeAndName(phoneNumberCountryCode);
      this.props.registerAction({
        email,
        password,
        firstName,
        lastName,
        phone,
        phoneCountryCode,
        phoneCountryName,
        facilityId: [...facility],
        departmentId: [...department],
        professionId: [...profession],
        specialtyId: [...specialty],
        suffixId: [...suffix],
        captchaResponse
      });
    }
  };

  render() {
    const { errors, firstName, email, lastName, phone, facility, department, profession, specialty, suffix, password, confirmPassword, phoneNumberCountryCode } = this.state;
    const { loading, history, callingCodes, facilitiesList, departmentsList, professionsList, specialtiesList, suffixesList, t } = this.props;


    const facilityOptions = checkProvidedOptions(facilitiesList.map(f => ({ value: f.id, label: f.name })), facility);
    const departmentOptions = checkProvidedOptions(departmentsList.map(f => ({ value: f.id, label: f.name })), department);
    const professionOptions = checkProvidedOptions(professionsList.map(f => ({ value: f.id, label: f.name })), profession);
    const specialityOptions = checkProvidedOptions(specialtiesList.map(f => ({ value: f.id, label: f.name })), specialty);
    const suffixOptions = checkProvidedOptions(suffixesList.map(f => ({ value: f.id, label: f.name })), suffix);

    if (loading) {
      return (
        <Container fluid className="pageContainer bg-light">
          <Loader />
        </Container>
      )
    }

    return (
      <Container fluid className="authPageContainer registerComponent pageContainer pt-64 bg-light">
        <div className="contentContainer w-100 py-20 justify-content-center d-flex flex-column align-items-center">
          <h1 className="authPageTitle">Register</h1>
          <Col lg={10} xs={12} className="d-flex flex-column align-items-center professionalInformationContainer">
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <InputField
                id="firstName"
                type="text"
                placeholder={t("Name")}
                value={firstName}
                error={errors.firstName}
                onChange={(event) => this.onFieldValueChange('firstName', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <InputField
                id="lastName"
                type="text"
                placeholder={t("Surname")}
                value={lastName}
                error={errors.lastName}
                onChange={(event) => this.onFieldValueChange('lastName', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <InputField
                id="email"
                type="email"
                placeholder={t("Email")}
                value={email}
                error={errors.email}
                onChange={(event) => this.onFieldValueChange('email', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 d-flex phoneNumberContainer accountSettingFieldContainer">
              <SelectField
                options={callingCodes.map(callingCode => {
                  const uniqueName = `(${callingCode.code}) - ${callingCode.country}`;
                  return {
                    value: uniqueName,
                    label: `(${callingCode.code})`,
                    optionLabel: uniqueName
                  }
                })}
                defaultValue={phoneNumberCountryCode}
                selectedValue={phoneNumberCountryCode}
                onChange={value => this.setState({ phoneNumberCountryCode: value })}
              />
              <InputField
                id="phoneNumber"
                type="tel"
                placeholder={t('auth.phone_num')}
                value={phone}
                error={errors.phone}
                onChange={(event) => this.onFieldValueChange('phone', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <div className="form-group">
                <MultiSelectField
                  name={'facility'}
                  placeholder={t('Facility')}
                  options={facilityOptions}
                  onChange={(newOptions) => this.onFieldValueChange('facility', newOptions.filter(option => option.checked).map(option => option.value))}
                />
                {errors.facility && <p className="errorText pl-2 mt-1">{errors.facility}</p>}
              </div>
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <div className="form-group">
                <MultiSelectField
                  name='department'
                  placeholder={t('Department')}
                  options={departmentOptions}
                  onChange={(newOptions) => this.onFieldValueChange('department', newOptions.filter(option => option.checked).map(option => option.value))}
                />
                {errors.department && <p className="errorText pl-2 mt-1">{errors.department}</p>}
              </div>
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <div className="form-group">
                <MultiSelectField
                  name='profession'
                  placeholder={t('cli_role')}
                  options={professionOptions}
                  isMulti={false}
                  withoutCheckboxes
                  onChange={(newOptions) => this.onFieldValueChange('profession', newOptions.filter(option => option.checked).map(option => option.value))}
                />
                {errors.profession && <p className="errorText pl-2 mt-1">{errors.profession}</p>}
              </div>
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <div className="form-group">
                <MultiSelectField
                  name='specialty'
                  placeholder={t('Specialty')}
                  options={specialityOptions}
                  onChange={(newOptions) => this.onFieldValueChange('specialty', newOptions.filter(option => option.checked).map(option => option.value))}
                />
                {errors.specialty && <p className="errorText pl-2 mt-1">{errors.specialty}</p>}
              </div>
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <div className="form-group">
                <MultiSelectField
                  name='suffix'
                  placeholder={t('Suffix')}
                  options={suffixOptions}
                  isMulti={false}
                  withoutCheckboxes
                  onChange={(newOptions) => this.onFieldValueChange('suffix', newOptions.filter(option => option.checked).map(option => option.value))}
                />
                {errors.suffix && <p className="errorText pl-2 mt-1">{errors.suffix}</p>}
              </div>
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <InputField
                id="password"
                type="password"
                placeholder={t('Password')}
                value={password}
                error={errors.password}
                required
                onChange={(event) => this.onFieldValueChange('password', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer">
              <InputField
                id="confirmPassword"
                type="password"
                placeholder={t('auth.confirm_pass')}
                value={confirmPassword}
                error={errors.confirmPassword}
                onChange={(event) => this.onFieldValueChange('confirmPassword', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleRegisterClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer yellow-card">
              <input
                id="confirmPassword"
                type="checkbox"
                checked={this.state.eula}
                value={confirmPassword}
                onChange={this.acceptEULA}
              />
              {t('auth.eula')} <a href='/eula' target='_blank'>{t('auth.eula_link')}</a> <br />
              {t('auth.this_opts_you')}
            </Col>
            <Col md={6} xs={12} className="px-0 accountSettingFieldContainer captcha">
              <ReCAPTCHA
                ref={r => this.recaptcha = r}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                onChange={(value) => {
                  // console.log('value', value);
                }}
              />
            </Col>

            <Col md={6} xs={12} className="d-flex px-0 w-100 accountSettingFieldContainer">
              <Button className="submitButton w-100" onClick={this.handleRegisterClick}>{t('auth.REGISTER')}</Button>
            </Col>


            <h1 className="authPageTitle mt-4">{t('auth.already_registered')}</h1>

            <Col md={6} xs={12} className="d-flex px-0 w-100 accountSettingFieldContainer">
              <Button className="cancelButton w-100" onClick={() => history.push(routes.login.path)}>{t('auth.back_to_login')}</Button>
            </Col>

          </Col>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(RegisterComponent))
