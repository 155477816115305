/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Button } from "reactstrap";
import { routes } from '../../config';
import "./index.scss";

import { InputField, Loader } from '../UIComponents';
import { withTranslation } from 'react-i18next';

class ForgotPasswordComponent extends Component {
  state = {
    email: '',
    errors: {},
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  componentDidMount() {
    const { token } = this.props;
    !!token && this.props.history.push(routes.recent.path)
  }

  componentDidUpdate(prevProps) {
    const { token, history, loading, error } = this.props, { loading: prevLoading } = prevProps;
    if (!!token) {
      history.push(routes.recent.path);
      return
    }
    if (!loading && prevLoading && !error) {
      history.push(routes.resetPassword.path)
    }
  }

  handleResetClick = () => {
    const { email } = this.state, errors = {};
    let isValid = true;

    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!email) {
      errors.email = this.props.t('Required');
      isValid = false;
    } else if (!re.test(email)) {
      errors.email = this.props.t('auth.invalid_email');
      isValid = false;
    }

    this.setState({ errors });
    isValid && this.props.sendForgotPasswordEmailAction({ username: email });
  };

  render() {
    const { errors, email } = this.state;
    const { loading, t } = this.props;

    if (loading) {
      return (
        <Container fluid className="pageContainer bg-light">
          <Loader />
        </Container>
      )
    }

    return (
      <Container fluid className="authPageContainer pageContainer pt-64 bg-light align-items-center">
        <div className="contentContainer w-100 py-5 justify-content-center d-flex flex-column align-items-center">
          <h1 className="authPageTitle">{t('auth.reset_pass')}</h1>
          <h1 className="authPageSubTitle">{t('auth.enter_email')}</h1>
          <Col lg={10} xs={12} className="d-flex flex-column align-items-center">
            <Col md={6} xs={12} className="px-0 ieCol mb-2">
              <InputField
                id="emailOrPhone"
                type="email"
                placeholder={t("Email")}
                value={email}
                error={errors.email}
                onChange={(event) => this.onFieldValueChange('email', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleResetClick()}
              />
            </Col>

            <Col md={6} xs={12} className="d-flex px-0 ieCol w-100 mb-5">
              <Button className="submitButton w-100" onClick={this.handleResetClick}>{t('admin.RESET_PASS')}</Button>
            </Col>

          </Col>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(ForgotPasswordComponent))
